export const typography = {
  logo: {
    fontFamily: "Gotham",
    fontWeight: 400,
  },

  light: {
    fontFamily: "JosefinSans",
    fontWeight: 300,
  },

  thin: {
    fontFamily: "JosefinSans",
    fontWeight: 100,
  },

  regular: {
    fontFamily: "JosefinSans",
    fontWeight: "normal",
  },

  medium: {
    fontFamily: "JosefinSans",
    fontWeight: 500,
  },

  semibold: {
    fontFamily: "JosefinSans",
    fontWeight: 600,
  },

  bold: {
    fontFamily: "JosefinSans",
    fontWeight: "bold",
  },
}

export const fontSizing = {
  mini: 10,
  tiny: 12,
  small: 14,
  medium: 16,
  mediumPlus: 18,
  large: 20,
  largePlus: 24,
  huge: 28,
  hugePlus: 32,
  enormous: 50,
  massive: 68,
  colossal: 120,
}

export const iconSizing = {
  micro: 12,
  tiny: 14,
  small: 18,
  medium: 22,
  default: 24,
  large: 30,
  huge: 50,
  massive: 72,
  colossal: 120,
}
