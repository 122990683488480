import { flow, getParent, Instance, IStateTreeNode, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { PitchWithPlaylistMetadata, PlaylistApi } from "../services/api/playlist-api"
import { Pitch, PitchModel } from "../models/pitch"
import {
  Playlist,
  PlaylistModel,
  PlaylistParams,
  SamplePlaylistType,
  ScheduledPlaylistPitchModel,
} from "../models/playlist"
import { withPitchStore } from "./pitch-store"
import { withSessionStore } from "./session-store"
import { ContentVisibility } from "../models/content-visibility"
import { withAssetStore } from "./asset-store"
import { RootStoreModel } from "./root-store"
import { withContentPermissionStore } from "./content-permission-store"
import { union } from "lodash-es"
import { AutoPostType } from "../models/auto-post-type"
import { withUserStore } from "./user-store"

export const PlaylistStoreModel = types
  .model("Playlist")
  .props({
    playlists: types.map(PlaylistModel),

    /**
     *  Map of playlist id => pitches
     */
    playlistPitches: types.map(types.array(types.safeReference(PitchModel))),

    /**
     * Map of group id => profile pitches
     */
    groupProfilePitches: types.map(types.array(types.safeReference(PitchModel))),

    /**
     *  Map of playlist id => pitch id => tags
     */
    playlistPitchTags: types.map(types.map(types.array(types.string))),

    /**
     *  Map of playlist id => pitch id => ordinal
     */
    playlistPitchOrdinals: types.map(types.map(types.number)),

    /**
     * Playlists the user has bookmarked
     */
    bookmarkedPlaylists: types.array(types.safeReference(PlaylistModel)),

    /**
     * Pitches that are scheduled for the for the given playlistId
     * Map of playlist id => pitches
     */
    scheduledPitches: types.map(types.array(ScheduledPlaylistPitchModel)),
    /**
     * Peer-to-peer playlists that have been pinned to home
     */
    pinnedPlaylists: types.array(types.safeReference(PlaylistModel)),
  })
  .extend(withEnvironment)
  .extend(withPitchStore)
  .extend(withSessionStore)
  .extend(withContentPermissionStore)
  .extend(withAssetStore)
  .extend(withUserStore)
  .actions((self) => ({
    putPlaylist(playlist: Playlist): Playlist {
      const user = playlist.user ? self.userStore.putUser(playlist.user) : undefined
      return self.playlists.put({ ...playlist, user: user?.id })
    },
  }))
  .actions((self) => ({
    putPlaylists(playlists: Playlist[]): Playlist[] {
      return playlists.map((playlist) => self.putPlaylist(playlist))
    },
    putGroupProfilePitches(groupId: string, pitchWithMetadata: PitchWithPlaylistMetadata[]) {
      const pitches = self.pitchStore.putPitches(pitchWithMetadata.map((p) => p.pitch))
      self.groupProfilePitches.set(
        groupId,
        pitches.map((p) => p.id),
      )
      return self.groupProfilePitches.get(groupId)
    },
    putPlaylistPitches: function ({
      playlistPitches,
      appendOnly,
    }: {
      playlistPitches: {
        [playlistId: string]: PitchWithPlaylistMetadata[]
      }
      appendOnly: boolean
    }) {
      for (const playlistId of Object.keys(playlistPitches)) {
        const pitches = playlistPitches[playlistId].map((p) => p.pitch)
        self.pitchStore.putPitches(pitches)
        // Sometimes if we didn't fetch the full list of pitches, such as in the search case
        // we don't want clear the existing list of pitches, instead just update the
        // fetched pitches
        if (self.playlistPitches.get(playlistId) && appendOnly) {
          const existingPitchIds = self.playlistPitches.get(playlistId)?.map((p) => p?.id)
          self.playlistPitches.set(
            playlistId,
            union(
              existingPitchIds,
              pitches.map((pitch) => pitch.id),
            ),
          )
        } else {
          self.playlistPitches.set(playlistId, pitches.map((pitch) => pitch.id) as any)
        }
        self.playlistPitchTags.set(playlistId, {})
        if (!self.playlistPitchOrdinals.get(playlistId)) {
          self.playlistPitchOrdinals.set(playlistId, {})
        }
        playlistPitches[playlistId].forEach((p) => {
          self.playlistPitchTags.get(playlistId)?.set(p.pitch.id, p.tags)
          self.playlistPitchOrdinals.get(playlistId)?.set(p.pitch.id, p.ordinal)
        })
      }
    },
  }))
  .actions((self) => ({
    fetchPlaylist: flow(function* (playlistId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getPlaylist(playlistId))
      return self.putPlaylists([result.playlist])[0]
    }),
    fetchPendingPitchActionPlaylists: flow(function* () {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getPendingPitchActionPlaylists())
      return self.putPlaylists(result.playlists)
    }),
    fetchSamplePlaylists: flow(function* (samplePlaylistTypes: SamplePlaylistType[]) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getSamplePlaylists(samplePlaylistTypes))
      self.putPlaylists(Object.values(result.samplePlaylists))
      return samplePlaylistTypes.reduce((acc, sampleType) => {
        // case insensitive key lookup
        const key =
          Object.keys(result.samplePlaylists).find(
            (k) => k.toLowerCase() === sampleType.toLowerCase(),
          ) || ""
        acc[sampleType] = self.playlists.get(result?.samplePlaylists[key]?.id)
        return acc
      }, {})
    }),
    fetchPlaylistPitches: flow(function* (playlistId) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getAllPitches(playlistId))
      self.putPlaylistPitches({
        playlistPitches: { [playlistId]: result.pitches },
        appendOnly: false,
      })
      return self.playlistPitches.get(playlistId)
    }),
    fetchGroupMemberProfilePitches: flow(function* (groupId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getGroupMemberProfilePitches(groupId))
      return self.putGroupProfilePitches(groupId, result.pitches)
    }),
    fetchScheduledPitches: flow(function* (playlistId) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getScheduledPitches(playlistId))
      self.scheduledPitches.set(
        playlistId,
        result.scheduledPitches.map((p) => {
          return {
            pitch: self.pitchStore.putPitch(p.pitch).id,
            scheduledUtc: p.scheduledUtc,
          }
        }),
      )
      return self.scheduledPitches.get(playlistId)
    }),
    createPlaylist: flow(function* (params: PlaylistParams) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.createPlaylist(params)
    }),
    updatePlaylist: flow(function* (params: PlaylistParams) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.updatePlaylist(params)
      const playlist = self.playlists.get(params.id)
      if (playlist) {
        playlist.name = params.name
        playlist.description = params.description
        playlist.visibility = params.visibility ?? ContentVisibility.External
        playlist.autoPostType = params.autoPostType ?? AutoPostType.Off
        playlist.closedUtc = params.closedUtc
      }
    }),
    setCoverPhotoAssetId: flow(function* (playlistId: string, assetId: string) {
      const playlist = self.playlists.get(playlistId)
      if (!playlist) {
        throw new Error("playlist not found for setCoverPhotoAssetId")
      }
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.updatePlaylist({ ...playlist, coverAssetId: assetId })
      playlist.coverAssetId = assetId
    }),
    setClosedUtc: flow(function* (playlistId: string, closedUtc?: Date) {
      const playlist = self.playlists.get(playlistId)
      if (!playlist) {
        throw new Error("playlist not found for setClosedUtc")
      }
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.updatePlaylist({ ...playlist, closedUtc })
      playlist.closedUtc = closedUtc
    }),
    setVisibilityAndAutoPostType: flow(function* (
      playlistId: string,
      visibility: ContentVisibility | string,
      autoPostType: AutoPostType | string,
    ) {
      const playlist = self.playlists.get(playlistId)
      if (!playlist) {
        throw new Error("playlist not found for setVisibilityAndAutoPostType")
      }
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.updatePlaylist({
        ...playlist,
        visibility,
        autoPostType,
      })
      playlist.visibility = visibility
      playlist.autoPostType = autoPostType
    }),
    setVisibility: flow(function* (playlistId: string, visibility: ContentVisibility) {
      const playlist = self.playlists.get(playlistId)
      if (!playlist) {
        throw new Error("playlist not found for setVisibility")
      }
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.updatePlaylist({ ...playlist, visibility })
      playlist.visibility = visibility
    }),
    setAutoPostType: flow(function* (playlistId: string, autoPostType: AutoPostType) {
      const playlist = self.playlists.get(playlistId)
      if (!playlist) {
        throw new Error("playlist not found for setAutoPostType")
      }

      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.updatePlaylist({ ...playlist, autoPostType })
      playlist.autoPostType = autoPostType
    }),
    movePitch: flow(function* (playlistId: string, pitchId: string, itemOrder: { key: string }[]) {
      const playlistApi = new PlaylistApi(self.environment.api)

      const foundIndex = itemOrder.findIndex(({ key }) => key === pitchId)
      if (foundIndex === -1) {
        throw new Error("Couldn't find moved video")
      }

      let nextPitchId: string | undefined
      if (foundIndex < itemOrder.length - 1) {
        nextPitchId = itemOrder[foundIndex + 1].key
      }

      const currentOrder = [...(self.playlistPitches.get(playlistId)?.map((p) => p?.id) || [])]

      self.playlistPitches.set(
        playlistId,
        itemOrder.map(({ key }) => key as any),
      )

      try {
        yield playlistApi.movePitch({
          playlistId,
          nextPitchId,
          pitchId,
        })
      } catch (err) {
        self.playlistPitches.set(playlistId, currentOrder)
        throw err
      }
    }),

    addPitches: flow(function* (playlistId: string, pitchIds: string[]) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.addPitches({
        playlistId: playlistId,
        pitchIds,
      })
      const playlistPitches: any = self.playlistPitches.get(playlistId) || []
      for (const pitchId of pitchIds) {
        playlistPitches.push(pitchId as any)
      }
      self.playlistPitches.set(playlistId, playlistPitches)
      const playlist = self.playlists.get(playlistId)
      if (playlist) {
        playlist.pitchCount += pitchIds.length
      }
    }),
    removePitches: flow(function* (playlistId: string, pitchIds: string[]) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const playlistPitches = self.playlistPitches.get(playlistId) || []
      const removedPitches: { pitch: Pitch | undefined; index: number }[] = []
      for (const pitchId of pitchIds) {
        const pitchIndex = playlistPitches.findIndex((p) => p?.id === pitchId)
        if (pitchIndex > -1) {
          removedPitches.push({ pitch: playlistPitches[pitchIndex], index: pitchIndex })
          playlistPitches.splice(pitchIndex, 1)
        }
      }
      self.playlistPitches.set(playlistId, playlistPitches)
      const playlist = self.playlists.get(playlistId)
      if (playlist) {
        const newCount = playlist.pitchCount - pitchIds.length
        playlist.pitchCount = Math.max(newCount, 0)
      }

      try {
        yield playlistApi.removePitches({
          playlistId: playlistId,
          pitchIds,
        })
      } catch (err) {
        for (const removedPitch of removedPitches) {
          playlistPitches.splice(removedPitch.index, 0, removedPitch.pitch)
        }
        self.playlistPitches.set(playlistId, playlistPitches)
        const playlist = self.playlists.get(playlistId)
        if (playlist) {
          playlist.pitchCount = pitchIds.length
        }
        throw err
      }
    }),
    deletePlaylist: flow(function* (playlistId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.deletePlaylist({ playlistId })
      self.playlists.delete(playlistId)
    }),
    fetchExistsInPlaylist: flow(function* (playlistId: string, pitches: Pitch[]) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const pitchIds = pitches.map((p) => p.id)
      if (pitches?.length) {
        const results = yield* toGenerator(playlistApi.existsInPlaylist(playlistId, pitchIds))
        const currentMap = self.playlistPitches.get(playlistId)?.map((p) => p?.id) || []
        const updatedMap = new Set([...currentMap, ...results.existingPitchIds])
        self.playlistPitches.set(playlistId, [...updatedMap] as any)
      }
      return self.playlistPitches
        .get(playlistId)
        ?.filter((p) => (p ? pitchIds.includes(p.id) : undefined) || [])
    }),
    fetchPeerToPeerPlaylists: flow(function* (userId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getPeerToPeerPlaylists(userId))
      return self.putPlaylists(result.playlists)
    }),
    fetchPinnedPeerToPeerPlaylists: flow(function* () {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getPinnedPeerToPeerPlaylists())
      const playlists = self.putPlaylists(result.playlists)
      self.pinnedPlaylists.replace(result.playlists.map((o) => o.id) as any)
      return playlists
    }),
    fetchGroupIntroductionPlaylist: flow(function* (groupId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const result = yield* toGenerator(playlistApi.getGroupIntroductionPlaylist(groupId))
      return self.putPlaylist(result.playlist)
    }),
    pinPeerToPeerPlaylist: flow(function* (userId: string, groupId: string) {
      const playlistApi = new PlaylistApi(self.environment.api)
      yield playlistApi.pinPeerToPeerPlaylist(userId, groupId)
    }),
    unpinPeerToPeerPlaylist: flow(function* (playlistId: string) {
      const originalPinnedPlaylists = self.pinnedPlaylists.slice()
      try {
        const playlistApi = new PlaylistApi(self.environment.api)
        // Optimistically remove the playlist from the pinned list
        self.pinnedPlaylists.replace(
          self.pinnedPlaylists.filter((playlist) => playlist?.id !== playlistId),
        )
        yield playlistApi.unpinPeerToPeerPlaylist(playlistId)
      } catch (err) {
        self.pinnedPlaylists.replace(originalPinnedPlaylists)
        throw err
      }
    }),
    existsInPlaylist(playlistId: string, pitchId: string) {
      return self.playlistPitches.get(playlistId)?.some((p) => p?.id === pitchId)
    },
  }))

export type PlaylistStore = Instance<typeof PlaylistStoreModel>
export const withPlaylistStore = (self: IStateTreeNode) => ({
  views: {
    get playlistStore(): PlaylistStore {
      return getParent<Instance<typeof RootStoreModel>>(self).playlistStore
    },
  },
})
