import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"
import { ContentVisibility } from "./content-visibility"
import { EntityModel } from "./entity"
import { UserProfileModel } from "./user-profile"

export enum PitchType {
  None = "None",
  Profile = "Profile",
  Job = "Job",
  Sales = "Sales",
  Media = "Media",
  Investment = "Investment",
  Admission = "Admission",
  Audition = "Audition",
  Athletic = "Athletic",
  Contest = "Contest",
  Suggestion = "Suggestion",
  Idea = "Idea",
  Donation = "Donation",
  News = "News",
}

export enum PitchLength {
  Standard = "Standard",
  Long = "Long",
  Extended = "Extended",
}

export enum PitchOrientation {
  Landscape = "Landscape",
  Portrait = "Portrait",
}

export enum PitchStatus {
  None = "None",
  Draft = "Draft",
  Published = "Published",
  Archived = "Archived",
}

export enum PitchActionType {
  Publish = "Publish",
  SetProfilePitch = "SetProfilePitch",
  AddToPlaylist = "AddToPlaylist",
  SendMessage = "SendMessage",
  PostToWall = "PostToWall",
  PeerToPeer = "PeerToPeer",
  GroupIntroduction = "GroupIntroduction",
}

export const PitchDetailsModel = types.model("PitchDetails").props({
  name: types.optional(types.string, ""),
  description: types.maybe(types.string),
  length: types.maybe(StringEnum(PitchLength)),
  orientation: types.maybe(StringEnum(PitchOrientation)),
  type: types.optional(StringEnum(PitchType), PitchType.None),
})

export interface PitchDetails extends Instance<typeof PitchDetailsModel> {}

export const PitchActionModel = types.model("PitchAction").props({
  actionTargetId: types.maybe(types.string),
  actionType: types.maybe(StringEnum(PitchActionType)),
  actionEntityId: types.maybe(types.string),
})

export interface PitchAction extends Instance<typeof PitchActionModel> {}

export const PitchModel = PitchDetailsModel.named("Pitch").props({
  id: types.identifier,
  userProfile: UserProfileModel,
  thumbnailAssetId: types.maybe(types.string),
  fullSizeAssetId: types.maybe(types.string),
  gifAssetId: types.maybe(types.string),
  status: types.maybe(StringEnum(PitchStatus)),
  entity: EntityModel,
  visibility: types.optional(StringEnum(ContentVisibility), ContentVisibility.Private),
  disableInteractions: types.optional(types.boolean, false),
  lastViewedUtc: types.maybe(DateTime),

  /**
   * This is CreatedUtc for drafts and PublishedUtc for published or archived pitches
   */
  displayUtc: types.maybe(DateTime),
})

export type Pitch = Instance<typeof PitchModel>
